@use "sass:math";

// Variables
@import 'variables';

body {
    .keynumbers {
        .keynumber {
            height: 100%;

            .card-body {
                position: relative;
                height: 100%;
                padding-bottom: 40px;

                .text-value {
                    font-size: 30px;
                    line-height: 34px;
                    margin: 5px 0 10px;

                    &.big {
                        font-size: 64px;
                        line-height: 64px;
                        font-weight: 700;
                        letter-spacing: -1px;
                    }

                    &.small {
                        font-size: 24px;
                        line-height: 24px;
                        @include maxlines(4);
                    }
                }

                .text-body {
                    a {
                        font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
                        color: #869ab8;
                        text-decoration: underline;
                        font-size: 16px;
                    }
                }

                .btn {
                    position: absolute;
                    bottom: 16px;
                    line-height: 26px;
                    font-size: 14px;
                    width: calc(100% - 40px);
                    padding: 0;
                }

                .tabledwidget {
                    margin-top:10px;
                    i {
                        margin-right: 5px;
                    }
                }
            }

            &.unpadded {
                .card-body {
                    padding-bottom: 0px;
                }
            }
        }
    }

    .plateinforetriever {
        display: none;
        cursor: pointer;
        position: absolute;
        right: 19px;
        top: 36px;
        line-height: 20px;
    }
    .paymentsummary {
        td {
            line-height: 38px;
            &:first-child {
                width: 50%;
                padding-left: 20px;
                font-size: 30px;
                font-weight: 700;
            }
            &:last-child {
                width: 20%;
            }
        }
    }
}